import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "@wordpress/element";
import { EffectFade } from "swiper";
import classnames from "classnames";
import packagesData from "../packages.json"; // Importing the JSON file with packages data

import "swiper/css";
import "swiper/css/effect-fade";

export default function App({ button, content, submitForm }) {
  const swiper = useRef(null);
  const [isPopupActive, setIsPopupActive] = useState(!button);
  const [values, setValues] = useState({
    step1: [],
    step2: {
      timelonnet: [],
      funktionaer: [],
    },
    step3: {
      timelonnet: null,
      funktionaer: null,
    },
    name: "",
    company: "",
    phone: "",
    email: "",
    acceptTerms: false, // Add this
  });

  const [packageType, setPackageType] = useState(null);
  const [isLastPage, setIsLastPage] = useState(false);
  const [errors, setErrors] = useState({});

  const { step_1, step_2, step_3, step_4, step_5, step_loading, general } = content?.translations;

  if (typeof window.__signup_active !== "function") {
    window.__signup_active = activePopup;
  }

  const settings = {
    modules: [EffectFade],
    draggable: false,
    allowTouchMove: false,
    autoHeight: true,
    effect: "fade",
    preventClicks: false,
    fadeEffect: {
      crossFade: true,
    },
    onSwiper: (_swiper) => {
      //swiper.current = _swiper;
      swiper.current = _swiper;
      window.swiperSignup = _swiper; // Expose to window
    },
    onSlideChange: (_swiper) => {
      setIsLastPage(_swiper.isEnd);
    },
  };

  function activePopup() {
    trackData("proevGratisOpen");

    document.body.classList.add("popup-is-open");
    setIsPopupActive(true);
  }

  async function trackData(event = null, data = {}) {
    if (!event) return;

    let data_object = {
      event: event,
      ...(Object.keys(data).length > 0 ? data : {}),
    };
    // Push event to the data layer (Google Tag Manager or similar)
    //dataLayer.push(data_object);

    //console.log("datalayer --> ", event, data_object);

    /*let data_object = {
      event: event,
    };
    // Push event to the data layer (Google Tag Manager or similar)
    dataLayer.push(data_object);

    console.log("datalayer --> ", event , data_object );*/

    // Prepare formData for the server-side tracking
    const formData = new FormData();
    formData.append("action", "intempus_analytics");
    formData.append("event", event); // Dynamically send event data

    await fetch(content?.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    });
  }

  function toggleCheckboxStep1(option) {
    setValues((prevValues) => {
      const newStep1 = prevValues.step1.includes(option) ? prevValues.step1.filter((item) => item !== option) : [...prevValues.step1, option];

      // Clear the error if something is selected
      if (newStep1.length > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          step1: false,
        }));
      }

      return {
        ...prevValues,
        step1: newStep1,
      };
    });
  }

  function toggleCheckboxStep2(group, option) {
    setValues((prevValues) => {
      // Update the selections for the given group (timelonnet or funktionaer)
      const newStep2Group = prevValues.step2[group].includes(option) ? prevValues.step2[group].filter((item) => item !== option) : [...prevValues.step2[group], option];

      // Clear the error for step2 if the group has any selections
      if (newStep2Group.length > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          step2: false, // Assuming step2 error is tracked by 'step2' key in errors
        }));
      }

      return {
        ...prevValues,
        step2: {
          ...prevValues.step2,
          [group]: newStep2Group,
        },
      };
    });
  }

  function toggleSwitchStep3(group, value) {
    setValues((prevValues) => ({
      ...prevValues,
      step3: {
        ...prevValues.step3,
        [group]: value,
      },
    }));

    // Clear the error when a valid selection is made
    setErrors((prevErrors) => ({
      ...prevErrors,
      step3: false,
    }));
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  async function handleSubmit() {
    // Convert null to false in step3 before submission
    const updatedStep3 = {
      timelonnet: values.step3.timelonnet !== null ? values.step3.timelonnet : false,
      funktionaer: values.step3.funktionaer !== null ? values.step3.funktionaer : false,
    };

    // Validate the final step's input fields
    const isValid = validateFinalStep();

    if (!isValid || !values.acceptTerms) {
      // If the validation fails, set an error for step4
      setErrors((prevErrors) => ({
        ...prevErrors,
        step4: true,
      }));
      return; // Stop the submission process if validation fails
    }

    let formData = {
      step1: values.step1,
      step2: values.step2,
      step3: updatedStep3,
      package: undefined,
      name: values.name,
      company: values.company,
      phone: values.phone,
      email: values.email,
      step1String: undefined,
      step2String: undefined,
      step3String: undefined,
    };

    // Calculate the package ID
    let package_data = calculatePackage(formData);

    //console.log(values);

    //console.log("andreas testing... ", formData?.step1, formData?.step2, formData?.step3);

    // Safely extract properties from the returned package_data
    let selectedPackageID = package_data?.id ?? null; // Use optional chaining to avoid errors if package_data is null
    let selectedCompanyPk = package_data?.company_pk ?? null; // Default to null if the property doesn't exist
    let selectedIdentifier = package_data?.identifier ?? null; // Default to null if the property doesn't exist

    // Merge the extracted values into formData
    formData.package = selectedPackageID;
    formData.company_pk = selectedCompanyPk;
    formData.identifier = selectedIdentifier;

    //console.log(formData);

    formData.step1String = get_step_value_as_string(formData?.step1);
    formData.step2String = get_step_value_as_string(formData?.step2);
    formData.step3String = get_step_value_as_string(formData?.step3);

    //console.log(formData);

    // Show the loading screen (slide index 5)
    swiper.current?.slideNext();

    // Wait for submitForm to finish
    const submit_form = await submitForm(formData);

    console.log("submit form response");
    console.log(submit_form);

    //We listen for submitForm
    // if error --> swiper.current?.slidePrev();
    // if succes --> swiper.current?.slideNext();

    trackData("proevGratis", values);

    // Move to the final step (slide index 6)
    swiper.current?.slideNext();
  }

  function get_step_value_as_string(stepData) {
    if (Array.isArray(stepData)) {
      return stepData.join(", ");
    } else if (typeof stepData === "object" && stepData !== null) {
      return Object.entries(stepData)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return `${key}: ${value.join(", ")}`;
          } else {
            return `${key}: ${value}`;
          }
        })
        .join("; ");
    } else {
      return String(stepData);
    }
  }

  function calculatePackage(formData) {
    // console.log("packagesData", packagesData);
    //console.log("user input", formData);

    // User input
    const { step1, step2, step3 } = formData;

    const matchedPackage1 = packagesData.find((item) => {
      const { step1: itemStep1, step2: itemStep2, step3: itemStep3 } = item.criteria;

      // Check step1: it should include all elements of searchCriteria.step1
      const step1Matches = step1.every((value) => itemStep1.includes(value));

      // Check step2: compare each subcategory of step2
      const step2Matches = Object.keys(step2).every((key) => {
        return JSON.stringify(step2[key]) === JSON.stringify(itemStep2[key]);
      });

      // Check step3: compare boolean values for step3
      const step3Matches = Object.keys(step3).every((key) => {
        return step3[key] === itemStep3[key];
      });

      return step1Matches && step2Matches && step3Matches;
    });

    // If a match is found, extract id, company_pk, and identifier
    if (matchedPackage1) {
      const { id, company_pk, identifier } = matchedPackage1;

      // Set package type for UI purposes
      setPackageType(matchedPackage1.name);

      console.log("Matched Package:", matchedPackage1);

      // Return the desired values as an object
      return { id, company_pk, identifier };
    }

    // If no match is found, return null and set default package type
    setPackageType("Custom");
    return null;
  }

  function validateStep1() {
    return values.step1.length > 0;
  }

  function validateStep2() {
    const isValidTimelonnet = !values.step1.includes("timelonnet") || values.step2.timelonnet.length > 0;
    const isValidfunktionaer = !values.step1.includes("funktionaer") || values.step2.funktionaer.length > 0;

    const isValid = isValidTimelonnet && isValidfunktionaer;

    if (!isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        step2: true,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        step2: false,
      }));
    }

    return isValid;
  }

  function validateStep3() {
    // Check if 'timelonnet' is included in step1 and has a valid selection (true/false)
    const isValidTimelonnet = !values.step1.includes("timelonnet") || values.step3.timelonnet !== null;

    // Check if 'funktionaer' is included in step1 and has a valid selection (true/false)
    const isValidfunktionaer = !values.step1.includes("funktionaer") || values.step3.funktionaer !== null;

    const isValid = isValidTimelonnet && isValidfunktionaer;

    if (!isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        step3: true, // Set error for step 3 if either is invalid
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        step3: false, // Clear step 3 error if valid
      }));
    }

    return isValid;
  }

  function validateFinalStep() {
    return values.name && values.company && values.phone && values.email;
  }

  function nextStep(currentStep) {
    let isValid = false;

    switch (currentStep) {
      case 1:
        isValid = validateStep1();
        if (isValid) trackData("proevGratisStep_1", values); // Track Step 1 completion
        if (!isValid) setErrors({ ...errors, step1: true });
        break;
      case 2:
        isValid = validateStep2();
        if (isValid) trackData("proevGratisStep_2", values); // Track Step 2 completion
        if (!isValid) setErrors({ ...errors, step2: true });
        break;
      case 3:
        isValid = validateStep3();
        if (isValid) trackData("proevGratisStep_3", values); // Track Step 3 completion
        if (!isValid) setErrors({ ...errors, step3: true });
        break;
      default:
        isValid = true;
        break;
    }

    if (isValid && swiper.current) {
      swiper.current.slideNext();
      setErrors({}); // Clear errors when moving to the next step
    }
  }

  function prevStep() {
    if (swiper.current) {
      swiper.current.slidePrev();
    }
  }

  useEffect(() => {
    if (isPopupActive) {
      document.body.classList.add("popup-is-open");
    } else {
      document.body.classList.remove("popup-is-open");
    }
  }, [isPopupActive]);

  // Resize swiper when errors are set
  useEffect(() => {
    if (swiper.current) {
      swiper.current.update(); // Update swiper to resize to the new content
    }
  }, [errors]);

  function closePopup() {
    setIsPopupActive(false);
    trackData("proevGratisClose");
  }

  return (
    <div
      className={classnames("popup-root intempus-popup-trigger-root", {
        "is-active": isPopupActive,
      })}>
      {!!button && <span className="intempus-popup-overlay" />}

      <div
        className={classnames("intempus-popup-trigger", {
          "is-last-page": isLastPage,
        })}>
        {!!button && (
          <button onClick={closePopup} className="intempus-popup-close">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
              <path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z" />
            </svg>
          </button>
        )}

        <Swiper {...settings}>
          {/* Step 1 */}
          <SwiperSlide>
            <div className="intempus-popup-screen">
              <div className="intempus-popup-header">
                <p className="title-h3">{step_1?.headline}</p>
                <p>{step_1?.sub_headline}</p>
              </div>

              <div className="intempus-popup-question">
                <p className="intempus-popup-question-heading">
                  {step_1?.question}
                  <span className="helper">{general?.helper_text}</span>
                </p>

                <div className="intempus-popup-radio is-type-checkbox">
                  <input id="timelonnet" name="timelonnet" type="checkbox" checked={values.step1.includes("timelonnet")} readOnly />
                  <label htmlFor="timelonnet" onClick={() => toggleCheckboxStep1("timelonnet")}>
                    {step_1?.choice_1}
                  </label>
                </div>

                <div className="intempus-popup-radio is-type-checkbox">
                  <input id="funktionaer" type="checkbox" checked={values.step1.includes("funktionaer")} readOnly />
                  <label htmlFor="funktionaer" onClick={() => toggleCheckboxStep1("funktionaer")}>
                    {step_1?.choice_2}
                  </label>
                </div>
              </div>

              {/* Conditional error message */}
              {errors.step1 && <div className="intempus-popup-error-message">{general?.error}</div>}

              <div className="intempus-popup-navs">
                <div className="wp-block-button is-style-default-outlined">
                  <button onClick={() => nextStep(1)} className="wp-block-button__link">
                    {general?.continue}
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Step 2 */}
          <SwiperSlide>
            <div className="intempus-popup-screen">
              {values.step1.includes("funktionaer") && (
                <div className="intempus-popup-question">
                  <p className="intempus-popup-question-heading bigger">
                    {step_2?.headline_one}
                    <span className="helper">{general?.helper_text}</span>
                  </p>

                  <div className="intempus-popup-radio is-type-checkbox">
                    <input id="funktionaer-app-web" type="checkbox" checked={values.step2.funktionaer.includes("app-and-web")} readOnly />
                    <label htmlFor="funktionaer-app-web" onClick={() => toggleCheckboxStep2("funktionaer", "app-and-web")}>
                      {step_2?.choice_1}
                    </label>
                  </div>
                  <div className="intempus-popup-radio is-type-checkbox">
                    <input id="funktionaer-terminal" type="checkbox" checked={values.step2.funktionaer.includes("terminal")} readOnly />
                    <label htmlFor="funktionaer-terminal" onClick={() => toggleCheckboxStep2("funktionaer", "terminal")}>
                      {step_2?.choice_2}
                    </label>
                  </div>
                </div>
              )}

              {values.step1.includes("timelonnet") && (
                <div className="intempus-popup-question">
                  <p className="intempus-popup-question-heading bigger">
                    {step_2?.headline_two}
                    <span className="helper">{general?.helper_text}</span>
                  </p>

                  <div className="intempus-popup-radio is-type-checkbox">
                    <input id="timelonnet-app-web" type="checkbox" checked={values.step2.timelonnet.includes("app-and-web")} readOnly />
                    <label htmlFor="timelonnet-app-web" onClick={() => toggleCheckboxStep2("timelonnet", "app-and-web")}>
                      {step_2?.choice_1}
                    </label>
                  </div>
                  <div className="intempus-popup-radio is-type-checkbox">
                    <input id="timelonnet-terminal" type="checkbox" checked={values.step2.timelonnet.includes("terminal")} readOnly />
                    <label htmlFor="timelonnet-terminal" onClick={() => toggleCheckboxStep2("timelonnet", "terminal")}>
                      {step_2?.choice_2}
                    </label>
                  </div>
                </div>
              )}

              {/* Conditional error message */}
              {errors.step2 && <div className="intempus-popup-error-message">{general?.error}</div>}

              <div className="intempus-popup-navs">
                <div className="wp-block-button is-style-primary-outlined is-style-default-outlined">
                  <button onClick={prevStep} className="wp-block-button__link">
                    {general?.back}
                  </button>
                </div>
                <div className="wp-block-button is-style-default-outlined">
                  <button onClick={() => nextStep(2)} className="wp-block-button__link">
                    {general?.continue}
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Step 3 */}
          <SwiperSlide>
            <div className="intempus-popup-screen">
              {values.step1.includes("funktionaer") && (
                <div className="intempus-popup-question">
                  <p className="intempus-popup-question-heading bigger">
                    {step_3?.headline_one}
                    <span className="helper">{general?.helper_text_two}</span>
                  </p>

                  <div className="intempus-popup-radio ">
                    <input id="funktionaer-projekter-yes" type="radio" checked={values.step3.funktionaer === true} readOnly />
                    <label htmlFor="funktionaer-projekter-yes" onClick={() => toggleSwitchStep3("funktionaer", true)}>
                      {step_3?.choice_1}
                    </label>
                  </div>
                  <div className="intempus-popup-radio">
                    <input id="funktionaer-projekter-no" type="radio" checked={values.step3.funktionaer === false} readOnly />
                    <label htmlFor="timelonnet-terminal" onClick={() => toggleSwitchStep3("funktionaer", false)}>
                      {step_3?.choice_2}
                    </label>
                  </div>
                </div>
              )}

              {values.step1.includes("timelonnet") && (
                <div className="intempus-popup-question">
                  <p className="intempus-popup-question-heading bigger">
                    {step_3?.headline_two}
                    <span className="helper">{general?.helper_text_two}</span>
                  </p>

                  <div className="intempus-popup-radio">
                    <input id="timelonnet-projekter-yes" type="radio" checked={values.step3.timelonnet === true} readOnly />
                    <label htmlFor="timelonnet-projekter-yes" onClick={() => toggleSwitchStep3("timelonnet", true)}>
                      {step_3?.choice_1}
                    </label>
                  </div>
                  <div className="intempus-popup-radio">
                    <input id="timelonnet-projekter-no" type="radio" checked={values.step3.timelonnet === false} readOnly />
                    <label htmlFor="timelonnet-projekter-no" onClick={() => toggleSwitchStep3("timelonnet", false)}>
                      {step_3?.choice_2}
                    </label>
                  </div>
                </div>
              )}

              {/* Conditional error message */}
              {errors.step3 && <div className="intempus-popup-error-message">{general?.error}</div>}

              <div className="intempus-popup-navs">
                <div className="wp-block-button is-style-primary-outlined is-style-default-outlined">
                  <button onClick={prevStep} className="wp-block-button__link">
                    {general?.back}
                  </button>
                </div>
                <div className="wp-block-button is-style-default-outlined">
                  <button onClick={() => nextStep(3)} className="wp-block-button__link">
                    {general?.continue}
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Step 4 */}
          <SwiperSlide>
            <div className="intempus-popup-screen">
              <div className="intempus-popup-header">
                <p className="title-h3">{step_4?.headline}</p>
                <p className="description">{step_4?.sub_headline}</p>
              </div>

              <div className="form-wrapper">
                <div className="it-form-row">
                  <label htmlFor="name">{step_4?.name}:</label>
                  <input type="text" id="name" name="name" value={values.name} onChange={handleInputChange} />
                </div>
                <div className="it-form-row">
                  <label htmlFor="company">{step_4?.company}:</label>
                  <input type="text" id="company" name="company" value={values.company} onChange={handleInputChange} />
                </div>
                <div className="it-form-row">
                  <label htmlFor="phone">{step_4?.phone}:</label>
                  <input type="text" id="phone" name="phone" value={values.phone} onChange={handleInputChange} />
                </div>
                <div className="it-form-row">
                  <label htmlFor="email">{step_4?.email}:</label>
                  <input type="email" id="email" name="email" value={values.email} onChange={handleInputChange} />
                </div>
              </div>

              <div className="intempus-popup-radio is-type-checkbox terms">
                <input
                  type="checkbox"
                  id="acceptTerms"
                  name="acceptTerms"
                  checked={values.acceptTerms || false}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setValues((prevValues) => ({
                      ...prevValues,
                      acceptTerms: isChecked,
                    }));

                    // Clear the error when the checkbox is checked
                    if (isChecked) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        acceptTerms: false,
                      }));
                    }
                  }}
                />
                <label
                  htmlFor="acceptTerms"
                  dangerouslySetInnerHTML={{
                    __html: step_4?.terms,
                  }}
                />
              </div>

              {/* Conditional error message */}
              {errors.step4 && <div className="intempus-popup-error-message">{general?.error}</div>}
              <div className="intempus-popup-error-message error-409">{general?.error409}</div>

              <div className="intempus-popup-navs">
                <div className="wp-block-button is-style-primary-outlined is-style-default-outlined">
                  <button onClick={prevStep} className="wp-block-button__link">
                    {general?.back}
                  </button>
                </div>
                <div className="wp-block-button is-style-default-outlined">
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="wp-block-button__link">
                    {step_4?.submit}
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Loading Screen */}
          <SwiperSlide>
            <div className="intempus-popup-screen final-screen-loading">
              <div className="intempus-popup-header">
                <p className="title-h3">{step_loading?.headline}</p>
                <p className="bold">{step_loading?.sub_headline}</p>
              </div>
              <img className="spinner" src="/wp-content/plugins/intempus-blocks/spinner.svg" />
              <div className="intempus-popup-body"></div>
            </div>
          </SwiperSlide>

          {/* Step 5 */}
          <SwiperSlide>
            <div className="intempus-popup-screen final-screen">
              <div className="intempus-popup-header">
                <p className="title-h3">{step_5?.headline}</p>
                <p className="bold">{step_5?.sub_headline}</p>
              </div>
              <div className="intempus-popup-body">{/*<p>{packageType}</p>*/}</div>
              <div className="intempus-popup-navs">
                <div className="wp-block-button is-style-default-outlined">
                  <a className="wp-block-button__link" href={step_5?.demo_link} target="_blank">
                    {step_5?.submit}
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
