import domReady from "@wordpress/dom-ready";
import { render } from "@wordpress/element";
import { __ } from "@wordpress/i18n";
import "./style.scss";
import App from "./app";

async function handleSubmit(data, url, error_msg_409, pipedrive) {
  const formData = new FormData();

  formData.append("action", "popup_signup");

  //console.log(data);
  const payload = {
    name: data.name,
    email: data.email,
    company: data.company,
    phone: data.phone,
    //industry: data?.package || undefined,
    company_pk: data?.company_pk || undefined,
    industry: data?.identifier || undefined,
  };

  formData.append("data", JSON.stringify(payload));

  function errorNotify() {
    const _payload = { ...payload, url: window?.location?.href || "Intempus" };

    const errorFormData = new FormData();

    errorFormData.append("action", "popup_error_notify");

    errorFormData.append("data", JSON.stringify(_payload));

    fetch(url, {
      method: "POST",
      credentials: "same-origin",
      body: errorFormData,
    });
  }

  function show_hide_error(show) {
    const error_message_div = document.getElementsByClassName("error-409")[0];
    
    if (error_message_div) {
      // Check if the element exists
      if (show) {
        error_message_div.classList.add("show");
        window?.swiperSignup.slidePrev();
        window?.swiperSignup.update();
      } else {
        error_message_div.classList.remove("show");
        window?.swiperSignup.update();
      }
    }
  }

  try {
    show_hide_error(false);

    //console.log(payload, "pinged --> https://staging-web.intempus.dk/web/v1/company/wordpress_signup/");

      const response = await fetch("//intempus.dk/web/v1/company/wordpress_signup/", {
      //const response = await fetch("//staging-web.intempus.dk/web/v1/company/wordpress_signup/", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    //const nextSlide = () => window.mySwiper?.slideNext();
    //const prevSlide = () => window.mySwiper?.slidePrev();

    // Ensure the response body is parsed before accessing it
    const responseData = await response.json();

    if (response?.status === 409) {
      const errorMessage = responseData?.message || "An unknown error occurred."; // Use API message or default

      errorNotify();
      show_hide_error(true);

      return Promise.reject({ message: error_msg_409 });
    }

    if (!response?.ok) {
      errorNotify();
      show_hide_error(true);
      return Promise.reject({ message: null });
    }

    await fetch(url, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    });

    /*
     * Pipedrive
     */
    console.log("Now call the Pipedrive function...");

    // 💡 Now call the Pipedrive function
    await submit_to_pipedrive(data, pipedrive, errorNotify);

    //window.mySwiper?.slideNext();

    window?.swiperSignup.slideNext();

    return Promise.resolve();
  } catch (error) {
    console.log(error);
    window?.swiperSignup.slidePrev();
    errorNotify();
    return Promise.reject({ message: null });
  }
}

/**
 * Planner Popup App
 */
function signupPopup() {
  const root = document.querySelectorAll(".button-signup-renderer");

  function renderPopup(rootNode, close = true) {
    const button = rootNode.querySelector(".wp-block-button__link");
    const node = rootNode.querySelector(".signup-renderer");

    if (!node) return;

    const hasInit = node.classList.contains("popup-init");
    if (hasInit) return;
    node.classList.add("popup-init");
    const content = JSON.parse(node.getAttribute("data-content"));

    if (!content) {
      console.warn("ACF popup_trigger data not found");
      return;
    }

    render(
      <App
        content={content}
        button={!!close ? button : null}
        submitForm={(data) => {
          return handleSubmit(data, content.ajax_url, content.error_message_409, content.pipedrive);
        }}
      />,
      node
    );
  }

  root.forEach((rootNode) => {
    renderPopup(rootNode);
  });

  const popupRendererRoot = document.querySelectorAll(".signup-renderer-root");
  popupRendererRoot.forEach((rootNode) => {
    renderPopup(rootNode, false);
  });
}

function signupButtons() {
  document.querySelectorAll(".signup-popup-toggle, .signup-render-button").forEach(function (button) {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      if (typeof window.__signup_active === "function") {
        window.__signup_active();
      }
    });
  });
}

function triggerOnLink() {
  if (window.location.hash === "#signup") {
    const plannerPopup = document.querySelector(".signup-render-button");
    plannerPopup.click();
  }
}

async function submit_to_pipedrive(data, pipedrive, errorNotify) {
  if (pipedrive.api_url && pipedrive.api_token) {
    const organization_id = parseInt(pipedrive.organization_id);
    const owner_id = parseInt(pipedrive.owner_id);

    if (isNaN(organization_id)) throw "Organization ID should be number";
    if (isNaN(owner_id)) throw "Owner ID should be number";

    const deviceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent || navigator.vendor || window.opera) ? "Mobile" : "Desktop";
    const referrer = document.referrer;

    const payload = {
      title: data.name,
      organization_id,
      owner_id,
    };

    const userPayload = {
      name: data.name,
      org_id: organization_id,
      owner_id,
    };

    if (data?.email) userPayload.email = data.email;
    if (data?.phone) userPayload.phone = data.phone;

    const {
      business_api_key,
      email_api_key,
      selected_plan_api_key,
      user_device,
      user_reference,
      browser_language,
      site_domain,
      /*
       * @ Signup specefic
       */
      type_of_employees,
      device_registration,
      project_registration,
    } = pipedrive;

    /*
    'type_of_employees' => '8afc69f95435df3bc0da2c7c5c4a58f557bf6646',
    'device_registration' => '1cdd5c6b0c3c0e7295b4af31e6d0193ecb73fce2',
    'project_registration' => '9352fbca2389121afe1116a46046091d4cee5c11',         
    */

    //if (account_option_api_key) payload[account_option_api_key] = data["account_option"];

    /*
     * @ Signup specefic
     */
    if (type_of_employees) payload[type_of_employees] = data["step1String"];
    if (device_registration) payload[device_registration] = data["step2String"];
    if (project_registration) payload[project_registration] = data["step3String"];

    //Company
    if (business_api_key) payload[business_api_key] = data["company"];
    if (email_api_key) payload[email_api_key] = data["email"];
    if (selected_plan_api_key) payload[selected_plan_api_key] = data["industry"];

    if (browser_language) payload[browser_language] = site_domain;
    if (user_device) payload[user_device] = deviceType;
    if (user_reference) payload[user_reference] = referrer;

    const token = `?api_token=${pipedrive.api_token}`;
    const leadsUrl = pipedrive.api_url?.replace(/\/$/, "") + "/leads" + token;
    const userUrl = pipedrive.api_url?.replace(/\/$/, "") + "/persons" + token;

    const response = await fetch(userUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userPayload),
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response?.data?.id) {
          await fetch(leadsUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...payload,
              person_id: response?.data?.id,
            }),
          });
        } else {
          if (!response?.ok) {
            errorNotify();
          }
          console.log("unable to create user", response);
        }
      });
  }
}

domReady(() => {
  const isEditor = document.getElementById("editor");
  if (!isEditor) {
    signupPopup();
    triggerOnLink();
    signupButtons();
    console.log("No editor");
  }
});
